import * as React from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
	color: '#191726',
	height: 4,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 20,
		width: 20,
		backgroundColor: '#fff',
		border: '8px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	// '& .MuiSlider-valueLabel': {
	// 	lineHeight: 1.2,
	// 	fontSize: 10,
	// 	background: 'unset',
	// 	padding: 0,
	// 	width: 32,
	// 	height: 32,
	// 	borderRadius: '50% 50% 50% 0',
	// 	backgroundColor: '#52af77',
	// 	transformOrigin: 'bottom left',
	// 	transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
	// 	'&:before': { display: 'none' },
	// 	'&.MuiSlider-valueLabelOpen': {
	// 		transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
	// 	},
	// 	'& > *': {
	// 		transform: 'rotate(45deg)',
	// 	},
	// },
});

const Scrollable = (props) => {
	return (
		<PrettoSlider
			valueLabelDisplay="auto"
			aria-label="pretto slider"
			valueLabelFormat={props.valueLabelFormat}
			step={props.stepValue || 1}
			min={props.minValue}
			max={props.maxValue}
			value={props.defaultValue}
			// defaultValue={props.defaultValue || 10}
			onChange={e => props.setValue && props.setValue(e.target.value)}
		/>
	);
};

export default Scrollable;
