import clsx from 'clsx';
import styles from './buttons.module.css';

export const Button = (props) => {
    const {
        onClick,
        onDoubleClick,
        className,
        selected,
        dark,
        fullWidth,
        centered,
        disabled,
        title,
        preIcon,
        slim,
        yellow,
        small,
        shady,
        type,
    } = props;
    return (
        <button
            className={clsx(styles.button, className, {
                [styles.selectedButton]: selected,
                [styles.fullWidthButton]: fullWidth,
                [styles.dark]: dark,
                [styles.centered]: centered,
                [styles.disabled]: disabled,
                [styles.slim]: slim,
                [styles.yellow]: yellow,
                [styles.small]: small,
                [styles.shady]: shady,
            })}
            disabled={disabled}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            type={type ? type : 'button'}
        >
            <>
                {preIcon && (
                    <>
                        <div className={styles.preIcon}>{preIcon}</div>
                    </>
                )}

                <div
                    className={clsx({
                        [styles.preIconTitle]: preIcon,
                    })}
                >
                    {title}
                </div>
            </>
        </button>
    );
};
