import clsx from 'clsx';
import styles from './forms.module.css';
import { CheckSquare, ChevronDown, MinusCircle, PlusCircle } from '../Svg';

export const TextInput = (props) => {
    const { gray, left, bordered, type, customInputClass, ...rest } = props;
    return (
        <input
            className={clsx(styles.textInput, {
                [styles.gray]: gray,
                [styles.left]: left,
                [styles.bordered]: bordered,
            }, customInputClass && customInputClass)}
            type={type ? type : 'text'}
            {...rest}
        />
    );
};

export const ValidationErrorMessage = (props) => {
    const { enabled, valid, description, className, ...rest } = props;
    if (!enabled || valid) return <></>;
    return (
        <div
            className={clsx(styles.validationErrorMessage, className)}
            {...rest}
        >
            {description}
        </div>
    );
};

export const Select = (props) => {
    const { children, fullWidth, ...rest } = props;
    return (
        <span className={styles.selectOuter}>
            <select
                className={clsx(styles.select, {
                    [styles.selectFullWidth]: fullWidth,
                })}
                {...rest}
            >
                {children}
            </select>
            <ChevronDown />
        </span>
    );
};

export const Checkbox = (props) => {
    const { checked, label, onChange } = props;
    return (
        <div className={styles.checkboxContainer}>
            <div
                className={clsx(styles.checkbox, {
                    [styles.checkboxChecked]: checked,
                })}
                onClick={() => onChange(!checked)}
            >
                {checked && <CheckSquare />}
            </div>
            <span className={styles.checkboxLabel}>{label}</span>
        </div>
    );
};

export const NumericInput = (props) => {
    const { placeholder, gray, left, bordered, type, ...rest } = props;
    return (
        <input
            className={clsx(styles.numericInput, {
                [styles.gray]: gray,
                [styles.left]: left,
                [styles.bordered]: bordered,
            })}
            type={type ? type : 'number'}
            placeholder={placeholder}
            {...rest}
        />
    );
};

export const CounterInput = (props) => {
    return (
        <div className={styles.counterInput}>
            <p className={styles.counterInputText}>{props.room} rum</p>
            <div className={styles.counterIconsWrapper}>
                <div className={styles.counterIconWrapper} onClick={() => props.handleRoomCounter('sub')}>
                    <MinusCircle />
                </div>
                <div className={styles.counterIconWrapper} onClick={() => props.handleRoomCounter('add')}>
                    <PlusCircle />
                </div>
            </div>
        </div>
    )
}




export const CheckboxList = (props) => {
    const { items, value, name, onValueChanged, ...rest } = props;
    const isChecked = (v) => value.indexOf(v) !== -1;
    const toggle = (v) => {
        const index = value.indexOf(v);
        if (index === -1) {
            value.push(v);
        } else {
            value.splice(index, 1);
        }
        onValueChanged([...value]);
    };
    return (
        <div className={styles.checkboxList}>
            {items.map((item) => (
                <label
                    key={item.value}
                    className={styles.checklistBoxContainer}
                >
                    {item.label}
                    <input
                        type="checkbox"
                        name={item.value}
                        onChange={() => toggle(item.value)}
                        checked={isChecked(item.value)}
                    />
                    <span className={styles.checkmark}></span>
                </label>
            ))}
        </div>
    );
};

export const RadioList = (props) => {
    const { items, value, name, onValueChanged, ...rest } = props;
    return (
        <div className={styles.radioList}>
            {items.map((item) => (
                <label
                    key={item.value}
                    htmlFor={name + item.value}
                    className={styles.radioButton}
                >
                    {item.label}
                    <input
                        checked={item.value === value}
                        type="radio"
                        id={name + item.value}
                        value={item.value}
                        name={name}
                        onChange={(e) => onValueChanged(e.currentTarget.value)}
                    ></input>
                    <span className={styles.radioCheckmark}></span>
                </label>
            ))}
        </div>
    );
};
