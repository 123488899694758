import React from 'react';
import Scrollable from './Scrollable';

function LoanCalculatorItem({ label, value, minValue, maxValue, stepValue, valueLabelFormat, setValue }) {
	return (
		<div className='loan_silderWrapper'>
			<div className='loan_selectorWrapper'>
				<p className="loan__selectorTitle">{label}</p>
				<p className='loan__selectorValue'>{valueLabelFormat}</p>
			</div>
			<Scrollable
				valueLabelFormat={valueLabelFormat}
				defaultValue={value}
				minValue={minValue}
				maxValue={maxValue}
				stepValue={stepValue}
				setValue={setValue}
			/>
		</div>
	);
}

export default LoanCalculatorItem;
