import React, { useState } from 'react';
import './calculateLoanPrice.css';
import LoanCalculatorItem from './LoanCalculatorItem';
import loan from '../../images/loan.png';
import {
	IconTips,
	IllustrationImage,
	LinkArrowLeft,
	LoanPreliminary,
	ProgressFinished,
	ProgressIndiCator,
} from '../Svg';
import Card from '../Card';
import { Button } from '../base/Buttons';
import { useHistory } from 'react-router-dom';
import useSessionStorage from '../../hooks/useSessionStorage';
import { RadioList } from '../base/Forms';
import { truncatePrice, extendedMortgage } from '../../util';
import analytics from '../../Analytics';

function CalculateLoanPrice() {
	const radioItems = [
		{ key: 1, label: 'Vad kan jag låna?', value: 1 },
		{ key: 2, label: 'Vad kan jag spara genom att lägga om lån?', value: 2 },
	];

	const history = useHistory();
	const [loanStatus, setLoanStatus] = useState(false);
	const [inquiry, setInquiry] = useSessionStorage('inquiry', null);
	const [whenSellOption, setWhenSellOption] = useSessionStorage('whenSellOption', null);
	const [mortGageCompletionStatus, setMortGageCompletionStatus] = useSessionStorage('mortGageCompletionStatus', null);
	const [mortgageData, setMortgageData] = useSessionStorage('mortgage', null)
	const [boughtFor, setBoughtFor] = useSessionStorage('boughtFor', null);
	const [valuationData, setValuationData] = useSessionStorage('valuation', null)
	// const [currentValuation, setcurrentValuation] = useState(inquiry?.estimate ? inquiry.estimate : null);
	const [cashDeposit, setCashDeposit] = useSessionStorage('cashDeposit', 0);
	const [currentLoan, setCurrentLoan] = useSessionStorage('currentLoan', 0);
	const [intRate, setIntRate] = useSessionStorage('intRate', 0);
	const [hIncome, setHIncome] = useSessionStorage('hIncome', 0);

	const [tempBoughtFor, setTempBoughtFor] = useState(boughtFor);
	const [tempCashDeposit, setTempCashDeposit] = useState(cashDeposit);
	const [tempCurrentLoan, setTempCurrentLoan] = useState(currentLoan)
	const [tempIntRate, setTempIntRate] = useState(intRate);
	const [tempHIncome, setTempHIncome] = useState(hIncome);

	const intialData = {
		boughtFor: boughtFor,
		cashDeposit: cashDeposit,
		currentLoan: currentLoan,
		intRate: intRate,
		hIncome: hIncome
	}

	const submitExtendedMortgage = async () => {
		const mortgageData = {
			current_loan: tempCurrentLoan,
			cash: tempCashDeposit,
			monthly_income: tempHIncome ? tempHIncome : 0,
			current_interest: tempIntRate ? tempIntRate : 0
		}
		setBoughtFor(tempBoughtFor);
		setCashDeposit(tempCashDeposit);
		setCurrentLoan(tempCurrentLoan);
		setIntRate(tempIntRate);
		setHIncome(tempHIncome);
		const extendedMortgazeData = await extendedMortgage(mortgageData);
		setMortgageData(extendedMortgazeData)
		setMortGageCompletionStatus(true);

		if (whenSellOption === 1) {
			analytics.sendEvent({
				action: 'Submit',
				category: 'Seller',
				label: 'Goal 4 - Loan New',
			});
		} else if (whenSellOption === 2) {
			analytics.sendEvent({
				action: 'Submit',
				category: 'Seller',
				label: 'Goal 5 - Loan Save',
			});
		}

		analytics.sendEvent({
			action: 'Submit',
			category: 'Seller',
			label: 'Loan 2',
		});
	}

	const resetData = () => {
		setTempBoughtFor(boughtFor);
		// setcurrentValuation(intialData.currentValuation);
		setTempCurrentLoan(currentLoan);
		setTempCashDeposit(cashDeposit);
		if (whenSellOption === 1) {
			setTempHIncome(hIncome);
		} else {
			setTempIntRate(intRate);
		}
	}

	return (
		<div className="LoanContainer">
			<div className="loanTopContainer">
				<div
					className="BackHandlerWrapper"
					onClick={() => history.goBack()}
				>
					<LinkArrowLeft />
					<span className="BackHandlerTitle">Tillbaka</span>
				</div>
			</div>
			<div className="LoanContentContainer">
				<div className="LoanCardWrapper">
					<React.Fragment>
						{whenSellOption == 1 ? (
							loanStatus ? (
								<Card
									type="custom"
									cardHeaderLeftIcon={<ProgressFinished />}
									cardHeaderTitle={'Lånelöfte'}
									className={'CustomCardStyle'}
								>
									<div className="cardBodyConatiner BgContainer">
										<div className="loanStatusWrapper">
											<div className="loanStatusDescWrapper">
												<div className="loanStatusValueWraaper">
													<div className="loanStatusSingleValueWrapper">
														<p className="singleValueTitle">
															{truncatePrice(mortgageData?.mortgage)}
														</p>
														<p className="singleValueValue">
															Borde du kunna låna
														</p>
													</div>
													{/* <div className="loanStatusSingleValueWrapper">
															<p className='singleValueTitle'>14,000 kr</p>
															<p className='singleValueValue'>Kostnad per månad</p>
														</div> */}
												</div>
												<p className="loanStatusDesc">
													Du kommer inom kort att bli
													kontaktad av en handläggare
													angående ditt lånelöfte.
												</p>
											</div>
											<div className="loanStatusLogoConatiner">
												<BankImg />
											</div>
										</div>
										<div className="btnContainer">
											<Button
												title={'Gör ny beräkning'}
												fullWidth
												centered
												onClick={() => setLoanStatus(false)}
											/>
											<Button
												title={'Klar'}
												dark
												fullWidth
												centered
												onClick={() => history.push('/dashboard')}
											/>
										</div>
									</div>
								</Card>
							) : (
								<Card
									type="custom"
									cardHeaderLeftIcon={
										<ProgressIndiCator percentage={50} />
									}
									cardHeaderTitle={'Kalkylera lånelöfte'}
									className={'CustomCardStyle'}
								>
									<div className="cardBodyConatiner">
										<div className="bankImg__box">
											<p>
												Vad får jag låna till drömhuset?
											</p>
											<BankImg />
										</div>
										<p>
											Justera togglarna nedan och få
											direkt ett preliminärt lånelöfte.
											Prata med våra handläggare för att
											få det bekräftat och börja ge dig ut
											på nya bostadsvisningar. Lycka till!
										</p>
										<div className="radioWrapper">
											<RadioList
												items={radioItems}
												value={whenSellOption}
												name="whenSellOption"
												onValueChanged={(val) => {
													setWhenSellOption(
														Number(val)
													);
												}}
											/>
										</div>
										<LoanCalculatorItem
											label={'Köpte för'}
											value={tempBoughtFor}
											valueLabelFormat={truncatePrice(tempBoughtFor)}
											setValue={(val) => setTempBoughtFor(val)}
											minValue={0}
											maxValue={10000000}
											stepValue={10000}
										/>
										<LoanCalculatorItem
											label={'Nuvarande värdering'}
											value={valuationData?.valuation}
											valueLabelFormat={truncatePrice(valuationData?.valuation)}
											// setValue={(val) => setcurrentValuation(val)}
											minValue={0}
											maxValue={10000000}
											stepValue={10000}
										/>
										<LoanCalculatorItem
											label={'Befintligt lån'}
											value={tempCurrentLoan}
											valueLabelFormat={truncatePrice(tempCurrentLoan)}
											setValue={(val) => setTempCurrentLoan(val)}
											minValue={0}
											maxValue={10000000}
											stepValue={10000}
										/>
										<LoanCalculatorItem
											label={'Kontantinsats vid köp'}
											value={tempCashDeposit}
											valueLabelFormat={truncatePrice(tempCashDeposit)}
											setValue={(val) => setTempCashDeposit(val)}
											minValue={0}
											maxValue={6000000}
											stepValue={10000}
										/>
										<LoanCalculatorItem
											label={'Hushållets månadsinkomst'}
											value={tempHIncome}
											valueLabelFormat={truncatePrice(tempHIncome)}
											setValue={(val) => setTempHIncome(val)}
											minValue={0}
											maxValue={200000}
											stepValue={1000}
										/>
										<div className="btnContainer">
											<Button
												title={'Återställ'}
												fullWidth
												centered
												onClick={() => resetData()}
											/>
											<Button
												title={'Uppdatera'}
												dark
												fullWidth
												centered
												onClick={() => { setLoanStatus(true); submitExtendedMortgage() }
												}
											/>
										</div>
									</div>
								</Card>
							)
						) : loanStatus ? (
							<Card
								type="custom"
								cardHeaderLeftIcon={<ProgressFinished />}
								cardHeaderTitle={'Besparingar på bolån'}
								className={'CustomCardStyle'}
							>
								<div className="cardBodyConatiner BgContainer">
									<div className="loanStatusWrapper">
										<div className="loanStatusDescWrapper">
											<div className="loanStatusValueWraaper">
												<div className="loanStatusSingleValueWrapper">
													<p className="singleValueTitle">
														{truncatePrice(mortgageData?.yearly_savings)}
													</p>
													<p className="singleValueValue">
														Potentiell besparing
													</p>
												</div>
												{/* <div className="loanStatusSingleValueWrapper">
															<p className='singleValueTitle'>14,000 kr</p>
															<p className='singleValueValue'>Kostnad per månad</p>
														</div> */}
											</div>
											<p className="loanStatusDesc">
												Du kommer inom kort att bli
												kontaktad av en handläggare
												angående ditt lånelöfte.
											</p>
										</div>
										<div className="loanStatusLogoConatiner">
											<BankImg />
										</div>
									</div>
									<div className="btnContainer">
										<Button
											title={'Gör ny beräkning'}
											fullWidth
											centered
											onClick={() => setLoanStatus(false)}
										/>
										<Button
											title={'Klar'}
											dark
											fullWidth
											centered
											onClick={() => history.push('/dashboard')}
										/>
									</div>
								</div>
							</Card>
						) : (
							<Card
								type="custom"
								cardHeaderLeftIcon={
									<ProgressIndiCator percentage={50} />
								}
								cardHeaderTitle={'Lägg om bolån'}
								className={'CustomCardStyle'}
							>
								<div className="cardBodyConatiner">
									<div className="bankImg__box">
										<p>
											Vad kan jag spara tills dess att jag
											flyttar?
										</p>
										<BankImg />
									</div>
									<div className="radioWrapper">
										<RadioList
											items={radioItems}
											value={whenSellOption}
											name="whenSellOption"
											onValueChanged={(val) => {
												setWhenSellOption(Number(val));
											}}
										/>
									</div>
									<LoanCalculatorItem
										label={'Köpte för'}
										value={tempBoughtFor}
										valueLabelFormat={truncatePrice(tempBoughtFor)}
										setValue={(val) => setTempBoughtFor(val)}
										minValue={0}
										maxValue={10000000}
										stepValue={10000}
									/>
									<LoanCalculatorItem
										label={'Nuvarande värdering'}
										value={valuationData?.valuation}
										valueLabelFormat={truncatePrice(valuationData?.valuation)}
										// setValue={(val) => setcurrentValuation(val)}
										minValue={0}
										maxValue={10000000}
										stepValue={10000}
									/>
									<LoanCalculatorItem
										label={'Kontantinsats vid köp'}
										value={tempCashDeposit}
										valueLabelFormat={truncatePrice(tempCashDeposit)}
										setValue={(val) => setTempCashDeposit(val)}
										minValue={0}
										maxValue={6000000}
										stepValue={10000}
									/>
									<LoanCalculatorItem
										label={'Befintligt lån'}
										value={tempCurrentLoan}
										valueLabelFormat={truncatePrice(tempCurrentLoan)}
										setValue={(val) => setTempCurrentLoan(val)}
										minValue={0}
										maxValue={10000000}
										stepValue={10000}
									/>
									<LoanCalculatorItem
										label={'Nuvarande ränta'}
										value={tempIntRate}
										valueLabelFormat={tempIntRate + '%'}
										setValue={(val) => setTempIntRate(val)}
										minValue={0}
										maxValue={6}
										stepValue={0.01}
									/>
									<div className="btnContainer">
										<Button
											title={'Återställ'}
											fullWidth
											centered
											onClick={() => resetData()}
										/>
										<Button
											title={'Uppdatera'}
											dark
											fullWidth
											centered
											onClick={() => { setLoanStatus(true); submitExtendedMortgage() }}
										/>
									</div>
								</div>
							</Card>
						)}
					</React.Fragment>
					<div className="LoanInnerCardWrapper">
						{!loanStatus && (
							<Card
								type="loan"
								cardHeaderLeftIcon={<LoanPreliminary />}
								cardHeaderTitle={'Bolån'}
								cardBodyTitle={
									'Fram tills det att du flyttar kan du spara tusenlappar!'
								}
								cardBodyDescription={
									'Kanske dags att investera i drömhuset? Beräkna ditt bolån här.'
								}
								cardBodyImg={<IllustrationImage />}
							/>
						)}
						<Card
							type="tips"
							cardHeaderLeftIcon={<IconTips />}
							cardHeaderTitle={'Tips'}
							tips={[
								{
									title: 'Belåningsgrad',
									description:
										"Ett tips är att få en mäklare att göra en mäklarvärdering. Denna värdering kan du sedan presentera för banken för att ändra din belåningsgrad, vilket kan göra att du sparar tusenlappar!",
								},
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

const BankImg = () => {
	return (
		<div className="bankImg__container">
			<img src={loan} alt="bank" />
		</div>
	);
};

export default CalculateLoanPrice;
