import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './app.css';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';

import { LoadScript } from '@react-google-maps/api';
import CalculateLoanPrice from './components/Loan/CalculateLoanPrice';
import Spinner from './components/spinner/Spinner';
const LoanPromise = lazy(() => import('./pages/LoanPromise'));
const InqueryContactFormPage = lazy(() =>
	import('./pages/InqueryContactFormPage')
);
const Inquiry = lazy(() => import('./pages/Inquiry'));
const InquiryBroker = lazy(() => import('./pages/InquiryBroker'));
const About = lazy(() => import('./pages/content/About'));
const FAQ = lazy(() => import('./pages/content/FAQ'));
const HowItWorks = lazy(() => import('./pages/content/HowItWorks'));
const Contact = lazy(() => import('./pages/content/Contact'));
const PurchaseTerms = lazy(() => import('./pages/content/PurchaseTerms'));
const Terms = lazy(() => import('./pages/content/Terms'));
const Brokers = lazy(() => import('./pages/content/Brokers'));
const PrivacyPolicy = lazy(() => import('./pages/content/PrivacyPolicy'));
const MapTabs = lazy(() => import('./pages/MapTabs'));
const StartPage = lazy(() => import('./pages/StartPage'));
const WidgetPage = lazy(() => import('./pages/WidgetPage'));
const SamtryggWidgetPage = lazy(() => import('./pages/SamtryggWidgetPage'));
const MapSearch = lazy(() => import('./pages/MapSearch'));
const InquiryGuide = lazy(() => import('./pages/InquiryGuide'));
const BrokersPage = lazy(() => import('./pages/BrokersPage'));
const ValuationPage = lazy(() => import('./pages/Valuation'));
const DashboardSharePage = lazy(() => import('./pages/DashboardShare'));

function Routes() {
	useGoogleAnalytics();
	return (
		<Switch>
			<Route path="/" exact component={StartPage} />
			<Route path="/widget" exact component={WidgetPage} />
			<Route path="/widget_sh5s3" exact component={SamtryggWidgetPage} />
			<Route path="/dashboard" exact component={MapTabs} />
			<Route
				path="/dashboard/:data"
				exact
				component={DashboardSharePage}
			/>
			<Route path="/brokers" exact component={BrokersPage} />
			<Route path="/loan" exact component={LoanPromise} />
			<Route path="/app/:name" exact component={MapTabs} />
			<Route
				path="/inquiry/contact"
				exact
				component={InqueryContactFormPage}
			/>
			<Route path="/inquiry/:id" exact component={Inquiry} />
			<Route path="/inquiry-broker/:id" exact component={InquiryBroker} />
			<Route path="/map" exact component={MapSearch} />
			<Route path="/inquiry_guide" exact component={InquiryGuide} />
			<Route path="/om-oss" exact component={About} />
			<Route path="/faq" exact component={FAQ} />
			<Route path="/for-maklare" exact component={Brokers} />
			<Route path="/sa-funkar-det" exact component={HowItWorks} />
			<Route path="/kontakt" exact component={Contact} />
			<Route path="/kopvillkor" exact component={PurchaseTerms} />
			<Route path="/villkor" exact component={Terms} />
			<Route path="/integritetspolicy" exact component={PrivacyPolicy} />
			<Route path="/valuation" exact component={ValuationPage} />
		</Switch>
	);
}

function App() {
	return (
		<LoadScript
			googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}
			loadingElement={<></>}
		>
			<BrowserRouter>
				<Suspense fallback={<Spinner />}>
					<Routes />
				</Suspense>
			</BrowserRouter>
		</LoadScript>
	);
}

export default App;
