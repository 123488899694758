export const truncatePrice = (p, decimals = 2) => {
    if (!p) return;
    let price = p;
    let unit = 'sek';
    if (price >= 1000000) {
        price = price / 1000000;
        unit = 'mkr';
    } else if (price >= 1000) {
        price = price / 1000;
        unit = 'tkr';
    } else {
        price /= 1;
    }

    return `${price.toFixed(decimals).replace('.', ',')} ${unit}`;
};

const reasons = [
    {
        name: 'Sälja bostaden',
        type: 'sell',
    },
    {
        name: 'Värdera bostaden',
        type: 'valuation',
    },
];

export const getReasons = () => reasons;
export const getReasonLabel = (type) => {
    const reason = reasons.find((r) => r.type === type);
    if (reason) return reason.name;
    return '';
};

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const fetchHousingDeals = async (options) => {
    const { lat, lng, maxDistance } = options;
    const response = await fetch(
        `${apiBaseUrl}/housing-deals/near?lat=${lat}&lng=${lng}&maxDistance=${maxDistance}`
    );
    const json = await response.json();
    return json;
};

export const fetchHousingDealsLatest = async (options) => {
    const response = await fetch(`${apiBaseUrl}/housing-deals/latest`);
    const json = await response.json();
    return json;
};

export const fetchBrokers = async (options) => {
    const { lat, lng, sort } = options;
    const response = await fetch(
        `${apiBaseUrl}/brokers/near?lat=${lat}&lng=${lng}&sort=${sort}`
    );
    const json = await response.json();
    return json;
};

export const fetchDealByBrokers = async (options) => {
    const { emails, lat, lng } = options;
    const response = await fetch(
        `${apiBaseUrl}/brokers/deals?emails=${emails}&lat=${lat}&lng=${lng}`
    );
    const json = await response.json();
    return json;
};

export const createLead = async (createLeadRequest) => {
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    console.log(isDev);
    const response = await fetch(`${apiBaseUrl}/leads?test_mode=${isDev}`, {
        method: 'POST',
        body: JSON.stringify(createLeadRequest),
    });
    const inquiry = await response.json();
    return inquiry;
};

export const createInquiry = async (createInquiryRequest) => {
    const response = await fetch(`${apiBaseUrl}/inquiries`, {
        method: 'POST',
        body: JSON.stringify(createInquiryRequest),
    });
    const inquiry = await response.json();
    return inquiry;
};

export const getInquiry = async (id, token) => {
    const response = await fetch(`${apiBaseUrl}/inquiries/${id}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
    const json = await response.json();
    return json;
};

export const getInquirySummary = async (id, token) => {
    // return {
    //     brokerReply: {
    //         replyStatus: null, // 'accpted', 'denied'
    //         acceptTimestamp: '2020-01-01',
    //         acceptRemoteIP: '123',
    //     },
    //     fullName: 'Test Testsson',
    //     telephoneNumber: '123 123 12 12',
    //     email: 'test@test.se',
    //     housingType: 'condominium',
    //     address: 'testgatan 1',
    //     livingArea: 60,
    //     numRooms: 2,
    //     estimate: '623451142',
    //     reason: 'sell',
    // };

    const response = await fetch(`${apiBaseUrl}/inquiries/${id}/summary`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
    const json = await response.json();
    return json;
};

export const fetchPlaces = async (term) => {
    const response = await fetch(
        `${apiBaseUrl}/place-search?term=${encodeURIComponent(term)}`
    );
    const json = await response.json();
    return json.predictions;
};

export const searchBrokers = async (q) => {


    const response = await fetch(
        `${apiBaseUrl}/brokers/search?q=${encodeURIComponent(q)}`,
        {
            method: "GET",
        }
    );
    return response.json();
};

export const fetchPlaceDetails = async (place) => {
    const response = await fetch(`${apiBaseUrl}/place/${place.place_id}`);
    const json = await response.json();
    return json;
};

export const fetchEstimate = async (options) => {
    const { lat, lng } = options;
    const response = await fetch(
        `${apiBaseUrl}/sqm-price?lat=${lat}&lng=${lng}`
    );
    const json = await response.json();
    return json;
};

export const brokerAccept = async function (id, token, data) {
    const response = await fetch(
        `${apiBaseUrl}/inquiries/${id}/broker-accept`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }
    );
    if (!response.ok) {
        throw response.status;
    }
    const json = await response.json();
    return json;
};

export const brokerDeny = async function (id, token) {
    const response = await fetch(`${apiBaseUrl}/inquiries/${id}/broker-deny`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
    const json = await response.json();
    return json;
};

export const extendedValuation = async (valuationData) => {
    const response = await fetch(`https://api.bytboo.com/valuation`, {
        method: 'POST',
        body: JSON.stringify(valuationData),
    });
    const extendedValuationData = await response.json();
    // const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
    //     method: "POST",
    //     body: JSON.stringify({
    //         title: 'foo',
    //         body: 'bar',
    //         userId: 1,
    //     }),
    // })
    // const data = await response.json();
    // let extendedValuationData = {
    //     value: 1000000000000
    // }
    return extendedValuationData;
};

export const extendedMortgage = async (mortgageData) => {
    console.log(mortgageData)
    let url = `${apiBaseUrl}/mortgage?current_loan=${mortgageData.current_loan}&cash=${mortgageData.cash}&monthly_income=${mortgageData.monthly_income}&current_interest=${mortgageData.current_interest}`;
    const response = await fetch(url);
    const extendedMortgageData = await response.json();
    // const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
    //     method: "POST",
    //     body: JSON.stringify({
    //         title: 'foo',
    //         body: 'bar',
    //         userId: 1,
    //     }),
    // })
    // const data = await response.json();
    // let extendedMortgageData = {
    //     value: 1000000000000
    // }
    return extendedMortgageData;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
};

export const setSessionValue = (key, value) => {
    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.log(error);
    }
};

export const byNumberSold = (a, b) => b.numberSold - a.numberSold;
export const byAvgPrice = (a, b) => b.avgSqmPrice - a.avgSqmPrice;
export const byScore = (a, b) => b.relativeBytBooScore - a.relativeBytBooScore;

export const getTopBrokersInCategory = (brokers) => {
    const topSales = brokers.sort(byNumberSold)[0];
    const topAvgPrice = brokers
        .sort(byAvgPrice)
        .find((p) => p !== topSales);
    const topScore = brokers
        .sort(byScore)
        .find((p) => p !== topSales && p !== topAvgPrice);
    return [
        Object.assign({}, topSales, { category: 'top-sales' }),
        Object.assign({}, topAvgPrice, { category: 'top-avg-price' }),
        Object.assign({}, topScore, { category: 'top-score' }),
    ];
};

export const getBrokerTag = (tag) => {
    if (tag === 'top-sales') {
        return 'Flest sålda'
    } else if (tag === 'top-avg-price') {
        return 'Bäst slutpris'
    } else if (tag === 'top-score') {
        return 'Bäst match'
    } else {
        return '';
    }
}
