import React from 'react';
import styles from './card.module.css';
import clsx from 'clsx';
import { getBrokerTag } from '../util';

const Card = (props) => {
    const {
        type = "default",
        cardHeaderLeftIcon,
        cardHeaderRightIcon,
        cardHeaderTitle,
        cardHeaderRightTitle,
        cardBodyTitle,
        cardBodyTitleImg,
        cardBodyDescription,
        broker,
        brokers,
        cardBodyImg,
        valuationGraph,
        shareBtn,
        emailBtn,
        isFirstCard,
        tips = [],
        className,
        onClick,
        id,
        shareLinkError
    } = props;
    return (
        <div className={clsx(styles.CardConatiner, isFirstCard && styles.FirstCard, className, onClick && styles.Cursor)} onClick={() => onClick && onClick()} id={id}>
            <div className={styles.CardHeaderWrapper}>
                <div className={styles.CardHeaderIconWrapper}>
                    {cardHeaderLeftIcon}
                </div>
                <h6 className={styles.CardHeaderTitle} title={cardHeaderTitle}>{cardHeaderTitle}</h6>
                {
                    cardHeaderRightIcon ?
                        <div className={clsx(styles.CardHeaderIconWrapper, styles.CardHeaderRightIconWrapper)}>
                            {cardHeaderRightIcon}
                        </div> :
                        cardHeaderRightTitle &&
                        <span className={styles.CardHeaderRightTitle}>{cardHeaderRightTitle}</span>
                }
            </div>
            <div className={styles.CardBodyWrapper}>
                {
                    (type === 'default' || type === 'broker' || type === 'house-share' || type === 'valuation' || type === 'loan' || type === 'information') &&
                    <>
                        <div className={styles.CardBodyInfoWrapper}>
                            <div className={styles.CardBodyTitleWrapper}>
                                <h3 className={clsx(styles.CardBodyTitle, type === 'information' && styles.InfoCardTitle)}>{cardBodyTitle}</h3>
                                {cardBodyTitleImg &&
                                    <div className={styles.CardBodyTitleImgWrapper}>
                                        {cardBodyTitleImg}
                                    </div>
                                }
                            </div>
                            <p className={styles.CardBodyDescription}>{cardBodyDescription}</p>
                            {
                                (type === 'broker' && broker) &&
                                <>
                                    <div className={styles.CardBodyDivider} />
                                    <Broker broker={broker} />
                                </>
                            }
                            {
                                type === 'house-share' &&
                                <div className={styles.CardBodyBtnWrapper}>
                                    {shareBtn && <>{shareBtn}<br /></>}
                                    {emailBtn && emailBtn}
                                    {shareLinkError && <p className={styles.linkError}>{shareLinkError}</p>}
                                </div>

                            }
                        </div>
                        {
                            cardBodyImg &&
                            <div className={styles.CardBodyImage}>
                                {cardBodyImg}
                            </div>
                        }
                        {
                            type === 'valuation' &&
                            <div className={styles.CardBodyImage}>
                                {valuationGraph}
                            </div>
                        }
                    </>
                }
                {
                    type === 'broker-list' &&
                    <div className={styles.CardBodyInfoWrapper}>
                        {brokers.map((brokerItem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Broker broker={brokerItem} />
                                    {index != brokers.length - 1 && <div className={styles.CardBodyDivider} />}
                                </React.Fragment>
                            )
                        })}
                    </div>
                }
                {
                    (type === 'custom') &&
                    <>
                        {props.children}
                    </>
                }
                {
                    (type === 'tips') &&
                    <div className={styles.CardBodyInfoWrapper}>
                        {
                            tips.map((tip, index) => (
                                <div className={styles.TipWrapper} key={tip.title + index}>
                                    <h6 className={styles.TipTitle}>{tip.title}</h6>
                                    <p className={styles.TipDescription}>{tip.description}</p>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const Broker = (props) => {
    const { broker } = props;

    return (
        <div className={styles.BrokerWrapper}>
            <div className={styles.BrokerImageWrapper}>
                <img src={broker.imageUrl} alt="" />
            </div>
            <div className={styles.BrokerInfoWrapper}>
                <b className={styles.BrokerTitle} title={broker.firstName + ' ' + broker.lastName}>{broker.firstName + ' ' + broker.lastName}</b>
                <p className={styles.BrokerQuote} title={broker.company}>{broker.company}</p>
            </div>
            {
                broker.category &&
                <div className={styles.BrokerRightWrapper}>
                    <div className={clsx(styles.BrokerTag,
                        broker.category === 'top-sales' && styles.BrokerTagSales,
                        broker.category === 'top-avg-price' && styles.BrokerTagPrice,
                        broker.category === 'top-score' && styles.BrokerTagScore,
                    )}>
                        <span className={styles.BrokerTagTitle}>{getBrokerTag(broker.category ? broker.category : '')}</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default Card